<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Creating frameworks</h3>

	<p>To create a new framework:</p>

	<ol>
		<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> an account with sufficient permissions.</li>
		<li>From the Framework Index page, click the <nobr><v-icon small>fas fa-plus</v-icon> NEW FRAMEWORK</nobr> button.</li>
		<li>Choose one of the options from the menu:</li>
		<ul>
			<li><b>Create a new framework from scratch:</b> The framework will be entirely blank awaiting the creation of its structure and items. See below to learn about information you'll need to provide in the “New Document” window.</li>
			<li><b>Import framework from CASE JSON:</b> You will be prompted to choose a JSON file from your computer as the imported framework source. The file can be in CASE 1.0 format or Extended CASE format.</li>
			<li><b>Mirror framework from another CASE server:</b> Creates a copy of a CASE-hosted framework in this instance of <span v-html="app_name"/>. A mirrored framework can be viewed, searched, annotated, and commented on; but its content cannot be changed, except by updates from the source framework. You can choose whether to have the mirror auto-updated on a daily basis or manually updated.</li> 
		</ul>
	</ol>
	<p><b>Completing the “New Document” details:</b></p>
	<p>Refer to <span v-html="link('case_document', 'CASE document metadata')"></span> for the meaning of the fields in the top section of the window. Next, complete the <b><span v-html="app_name"/> Settings</b> section:</p>
	<ul>
		<li><b>Color:</b> The color the box around the framework/document will have in the Framework Index page and elsewhere. Leaving this set to “Default“ will automatically base the color on the Subject set for the framework/document.</li>
		<li><b>Image:</b> The icon the framework/document will have in the Framework Index page Tile view and elsewhere. Leaving this set to “Default“ will automatically base the icon on the Subject set for the framework/document. From the drop-down menu, you can also choose to paste in your own custom image.</li>
		<li><b>Category:</b> How the framework/document will be grouped in the Framework Index page. Select from the drop-down menu, or use “New Category” button to create a new one.</li>
		<li><b>URL Shortcuts:</b> <i>pending</i></li>
		<li><b>Supp. Info Label:</b> <i>pending</i></li>
		<li><b>Provide LaTeX editing support:</b> Enable the entry of text using the <a href="https://latex-tutorial.com/tutorials/amsmath/" target="_blank">LaTeX</a> markup language for generating mathematical and scientific expressions.</li>
		<li><b>Show associations to framework reviewers and editors only:</b> Leave this unchecked to hide the indicators of related standards from the <span v-html="link('item_card', 'Item Card')"></span> for users with view-only permissions.</li>
	</ul>



</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	